import React from 'react'

import { Flex, Text } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { ButtonArrow } from 'src/components/ButtonArrow'
import { Physician as PhysicianBase } from 'src/components/Physician'
import { Subheading } from 'src/components/Subheading'

const Physician = props => (
  <PhysicianBase
    as="li"
    width={[1 / 2, 3 / 12, 2 / 12]}
    mb={[2, 4]}
    px={2}
    {...props}
  />
)

export const RelatedPhysicians = ({ children, backTo, ...props }) => (
  <BoundedBox as="section" bg="sandExtraLight" color="navy" {...props}>
    <Subheading color="inherit" mb={1} textAlign="center">
      Related Physicians
    </Subheading>
    <Text textAlign="center" mb={[4, 6]}>
      Talk to one of our specialists to find out more.
    </Text>
    <Flex as="ul" flexWrap="wrap" justifyContent="center" mb={2}>
      {children}
    </Flex>
    {backTo && (
      <Flex flexDirection="column" alignItems="center">
        <ButtonArrow to={backTo} direction="left" mx="auto">
          Back
        </ButtonArrow>
      </Flex>
    )}
  </BoundedBox>
)

RelatedPhysicians.Physician = Physician
