import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map, compose, find, propEq } from 'lodash/fp'
import { nodes } from 'helpers'

import { Layout } from 'src/components/Layout'

import { BodyCopy } from 'src/slices/BodyCopy'
import { PageTitle } from 'src/slices/PageTitle'
import { RelatedPhysicians } from 'src/slices/RelatedPhysicians'
import { Summary } from 'src/slices/Summary'

const ServiceTemplate = ({ data }) => {
  const service = get('markdownRemark', data)
  const physicians = compose(
    nodes,
    get('physicians')
  )(data)
  const relatedPhysicians = compose(
    map(x =>
      find(propEq('frontmatter.path', get('physicianRelation', x)), physicians)
    ),
    get('frontmatter.relatedPhysicians')
  )(service)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', service)}</title>
      </Helmet>
      <PageTitle
        imageFluid={get('frontmatter.image.childImageSharp.fluid', service)}
      >
        {get('frontmatter.title', service)}
      </PageTitle>
      <Summary markdown={get('frontmatter.description', service)} />
      <BodyCopy
        heading={get('frontmatter.subtitle', service)}
        markdown={get('rawMarkdownBody', service)}
      />
      <RelatedPhysicians backTo="/services/">
        {map(
          physician => (
            <RelatedPhysicians.Physician
              key={get('id', physician)}
              imageFluid={get(
                'frontmatter.photo.childImageSharp.fluid',
                physician
              )}
              name={get('frontmatter.title', physician)}
              path={get('frontmatter.path', physician)}
              phoneNumber={get('frontmatter.phoneNumber', physician)}
            />
          ),
          relatedPhysicians
        )}
      </RelatedPhysicians>
    </Layout>
  )
}

export default ServiceTemplate

export const query = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        descriptionHeading: description_heading
        description
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        subtitle
        relatedPhysicians: related_physicians {
          physicianRelation: physician_relation
        }
      }
      rawMarkdownBody
    }
    physicians: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(physicians)/.*.md$/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            photo {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            phoneNumber: phone_number
          }
        }
      }
    }
  }
`
